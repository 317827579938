(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/scroll-aware-top-overlay/assets/javascripts/scroll-aware-top-overlay.js') >= 0) return;  svs.modules.push('/components/lb-ui/scroll-aware-top-overlay/assets/javascripts/scroll-aware-top-overlay.js');
"use strict";


const {
  useState,
  useEffect,
  useRef
} = React;
const {
  throttle
} = _;
const {
  TopOverlayWrapper
} = svs.components.lbUi.scrollAwareTopOverlay;
const ScrollAwareTopOverlay = _ref => {
  let {
    children,
    containerElementSelector,
    intersectionElementSelector,
    intersectionThreshold = 0.5,
    onTopOverlayWrapperClick,
    showTopOverlay
  } = _ref;
  const [isIntersectionElementVisible, setIsIntersectionElementVisible] = useState(true);
  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const [containerTopOffset, setContainerTopOffset] = useState(null);
  const lastScrollTop = useRef(0);
  useEffect(() => {
    const intersectionElement = intersectionElementSelector ? document.querySelector(intersectionElementSelector) : null;
    const containerElement = containerElementSelector ? document.querySelector(containerElementSelector) : null;
    const handleScroll = throttle(() => {
      const scrollTop = containerElement ? containerElement.scrollTop : window.scrollY || document.documentElement.scrollTop;
      setIsScrollingUp(scrollTop < lastScrollTop.current);
      lastScrollTop.current = scrollTop <= 0 ? 0 : scrollTop;
    }, 200);
    const observer = new IntersectionObserver(_ref2 => {
      let [entry] = _ref2;
      setIsIntersectionElementVisible(entry.isIntersecting);
    }, {
      root: containerElement,
      threshold: [intersectionThreshold]
    });
    if (intersectionElement) {
      observer.observe(intersectionElement);
    }
    if (containerElement) {
      const containerElementRect = containerElement.getBoundingClientRect();
      setContainerTopOffset(containerElementRect.top);
      containerElement.addEventListener('scroll', handleScroll, {
        passive: true
      });
    } else {
      window.addEventListener('scroll', handleScroll, {
        passive: true
      });
    }
    return () => {
      if (intersectionElement) {
        observer.unobserve(intersectionElement);
      }
      if (containerElement) {
        containerElement.removeEventListener('scroll', handleScroll);
      } else {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, [containerElementSelector, intersectionElementSelector, intersectionThreshold]);
  return React.createElement(TopOverlayWrapper, {
    containerTopOffset: containerTopOffset,
    isVisible: !isIntersectionElementVisible && (isScrollingUp || showTopOverlay),
    onClick: onTopOverlayWrapperClick
  }, children);
};
setGlobal('svs.components.lbUi.scrollAwareTopOverlay.ScrollAwareTopOverlay', ScrollAwareTopOverlay);

 })(window);