(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/scroll-aware-top-overlay/assets/javascripts/top-overlay-wrapper.js') >= 0) return;  svs.modules.push('/components/lb-ui/scroll-aware-top-overlay/assets/javascripts/top-overlay-wrapper.js');
"use strict";


const {
  useEffect,
  useRef
} = React;
const TopOverlayWrapper = _ref => {
  let {
    children,
    isVisible = false,
    containerTopOffset,
    onClick
  } = _ref;
  const overlayComponentRef = useRef();
  const isInitialRender = useRef(true);
  useEffect(() => {
    if (overlayComponentRef.current) {
      if (isInitialRender.current) {
        isInitialRender.current = false;
        gsap.set(overlayComponentRef.current, {
          autoAlpha: 0,
          opacity: 0
        });
      }
      if (isVisible) {
        gsap.fromTo(overlayComponentRef.current, {
          autoAlpha: 0,
          opacity: 0
        }, {
          autoAlpha: 1,
          duration: 0.5,
          ease: 'power1.out',
          opacity: 1
        });
      } else {
        gsap.to(overlayComponentRef.current, {
          autoAlpha: 0,
          duration: 0.5,
          ease: 'power1.in'
        });
      }
    }
  }, [isVisible]);
  const blockClassName = 'pg_top_overlay_wrapper';
  const classNames = [blockClassName].concat(typeof containerTopOffset !== 'number' ? ["".concat(blockClassName, "--has-no-container")] : [], onClick ? ["".concat(blockClassName, "--is-clickable")] : []);
  return React.createElement("div", {
    className: classNames.join(' '),
    onClick: onClick,
    ref: overlayComponentRef
  }, children);
};
setGlobal('svs.components.lbUi.scrollAwareTopOverlay.TopOverlayWrapper', TopOverlayWrapper);

 })(window);